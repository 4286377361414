<template>
    <nav
        class="vue-nav-1"
        :data-expanded="isExpanded"
        :data-headertemplate="template"
        :data-navtemplate="navTemplate"
    >
        <div class="mobile-controls">
            <p class="close-mobile-menu" @click="closeMobileMenu">
                <span class="fas fa-chevron-left"></span>
            </p>
        </div>
        <template v-for="ni in navInfo">
            <vue-nav-item
                v-if="ni.page.show_in_nav"
                :key="ni.page.id"
                :depth="0"
                :nav-item="ni"
                :current-page-id="currentPageId"
            ></vue-nav-item>
        </template>
    </nav>
</template>

<script>
export default {
    props: [
        "currentPageId",
        "mobileExpanded",
        "navInfo",
        "navTemplate",
        "template"
    ],
    computed: {
        isExpanded() {
            return this.mobileExpanded;
        }
    },
    methods: {
        closeMobileMenu(event) {
            this.$emit('close-mobile-menu');
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $template-theme: var(--template-theme, white);
    $theme-compliant: var(--theme-compliant, black);
    $theme-non-compliant: var(--theme-noncompliant, white);

    .vue-nav-1 {
        z-index: 2;

        @include desktop {
            display: flex;
            align-items: stretch;
            margin: 0 1rem;

            .mobile-controls { display: none }

            // Global Styling
            .vn-item {
                cursor: pointer;
                margin: 0;
                line-height: 1.25;
                color: $theme-compliant;
                text-decoration: none;

                &:hover { text-decoration: underline; }
            }

            // Root Level Families
            [data-depth="0"] {
                &.vn-family {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-color: $theme-non-compliant;
                        opacity: 0;
                        transition: 200ms ease-out;
                    }

                    // When hovering over a family...
                    &:hover {
                        &:before { opacity: 1; }

                        [data-depth="0"] {
                            &.vn-item:before { width: calc(100% - 2rem); }
                            &.vn-children { display: flex; }
                        }
                    }

                    // If family is current and in the top level nav
                    &[data-current] [data-depth="0"].vn-item:before { width: calc(100% - 2rem); }
                }

                &.vn-item {
                    @include fluid-size(16px, 18px, font-size, $min-vw: $lg-screen, $max-vw: 1400px);
                    padding: 1rem;
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    color: $theme-compliant;
                    text-decoration: none;
                    text-transform: uppercase;

                    &:before {
                        content: '';
                        position: absolute;
                        bottom: .75rem;
                        height: 3px;
                        width: 0;
                        left: 1rem;
                        transition: 200ms ease-out;
                        background-color: $theme-compliant;
                    }
                }

                &.vn-children {
                    display: none;
                    flex-direction: column;
                    align-items: stretch;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    padding: .5rem 1rem;
                    width: max-content;
                    min-width: 100%;
                    background-color: $theme-non-compliant;
                }
            }

            // First Level Families
            [data-depth="1"] {
                &.vn-family {
                    flex: 1 1 0;
                    display: flex;
                    flex-direction: column;
                    padding: .25rem 0;
                }

                &.vn-item {
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            // Second Level Families
            [data-depth="2"] {
                &.vn-item {
                    display: block;
                    font-size: 14px;
                    padding: .125rem 0 .125rem .5rem;
                }
            }
        }

        @include not-desktop {
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            background-color: $template-theme;
            height: 100vh;
            width: 100%;
            top: 0;
            right: -100%;
            transition: 200ms ease-out;

            &[data-expanded] { right: 0; }

            .mobile-controls {
                display: flex;
                background-color: $theme-compliant;

                .close-mobile-menu {
                    color: $theme-non-compliant;
                    margin: 0;
                    padding: 1rem;
                }
            }

            .vn-item {
                color: $theme-compliant;
                font-family: "benton-sans-condensed", sans-serif;
                font-weight: bold;
                line-height: 1;
                text-transform: uppercase;
            }

            .vn-family {
                & > .vn-children {
                    max-height: 0;
                    height: max-content;
                    overflow: hidden;
                    transition: 200ms ease-out;
                }

                &[data-expanded] > .vn-children {
                    max-height: 50vh;
                    transition: 200ms ease-in;
                }
            }

            // Root Level Families
            [data-depth="0"] {
                &.vn-family {
                    background-color: $template-theme;
                    border-bottom: 3px solid $theme-compliant;
                    position: relative;

                    &[data-has-children] {
                        & > .vn-item {
                            &:before, &:after {
                                content: '';
                                position: absolute;
                                pointer-events: none;
                            }

                            &:before {
                                width: 40px;
                                height: 40px;
                                top: 50%;
                                left: calc(200% - 40px - 1rem);
                                transform: translateY(-50%);
                                border-radius: 50rem;
                                background-color: $theme-compliant;
                            }

                            &:after {
                                border-width: 3px;
                                border-style: solid;
                                border-color: transparent $theme-non-compliant $theme-non-compliant transparent;
                                height: 15px;
                                width: 15px;
                                transform: translate(-15%, 62.5%) rotate(45deg); // These are magic numbers
                                transform-origin: 62.5% 62.5%; // These are the most magical numbers and I don't know why
                                left: calc(200% - 27.5px - 1rem); // 27.5 is from (circle width / 2) + (arrow width / 2)
                                top: 15%;
                                transition: 200ms ease-out;
                            }
                        }

                        &[data-expanded] > .vn-item {
                            &:after {
                                transform: translate(-15%, 62.5%) rotate(-135deg); // These are magic numbers
                            }
                        }
                    }
                }

                &.vn-item {
                    @include rem(22px);
                    padding: 1rem;
                    display: block;
                    position: relative;
                    margin-right: 50%;
                }

                &.vn-children {
                    display: flex;
                    flex-direction: column;
                }
            }

            [data-depth="1"] {
                &.vn-family {
                    background-color: $theme-compliant;
                    border-bottom: 1px solid $template-theme;

                    &[data-has-children] {
                        & > .vn-item {
                            &:after {
                                content: '';
                                position: absolute;
                                pointer-events: none;
                                border-width: 2px;
                                border-style: solid;
                                border-color: transparent $theme-non-compliant $theme-non-compliant transparent;
                                height: 10px;
                                width: 10px;
                                transform: translate(-15%, 62.5%) rotate(45deg); // These are magic numbers
                                transform-origin: 62.5% 62.5%; // These are the most magical numbers and I don't know why
                                left: calc(200% - 25px - 1rem); // 25 is from (circle width / 2) + (arrow width / 2)
                                top: 10%;
                                transition: 200ms ease-out;
                            }
                        }

                        &[data-expanded] > .vn-item {
                            &:after {
                                transform: translate(-15%, 62.5%) rotate(-135deg); // These are magic numbers
                            }
                        }
                    }

                    &:last-child { border-width: 0; }
                }

                &.vn-item {
                    @include rem(16px);
                    padding: .5rem 1rem;
                    display: block;
                    position: relative;
                    margin-right: 50%;
                    color: $theme-non-compliant;
                }
            }

            [data-depth="2"] {
                &.vn-item {
                    @include rem(14px);
                    font-weight: normal;
                    text-transform: none;
                    padding: .5rem 1.5rem;
                    display: block;
                    position: relative;
                    margin-right: 50%;
                }
            }

            [data-depth="3"] { display: none; }
        }
    }

</style>