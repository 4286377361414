<template>
    <div class="alert alert-danger" v-if="message || errors.length">
        <p v-if="message"><strong>{{ message }}</strong></p>
        <ul v-if="Object.entries(errors).length">
            <li v-for="(message, name) in errors">
                {{ name }}: {{ message.join(', ') }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
        errors: Object
    },
    watch: {
        errors: {
            deep: true,
            handler(n, o) {
                let currentErrors = document.querySelectorAll('[name].error')
                
                Array.from(currentErrors).forEach(input => {
                    input.classList.remove('error')
                })

                Object.entries(n).forEach(([name, message]) => {
                    let control = document.querySelector(`[name="${name}"]`)

                    control.classList.add('error')
                })
            }
        }
    }
}
</script>

