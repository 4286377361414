<template>
    <component
        v-if="navTemplateName != 'hidden'"
        :is="templateComponent"
        :additional-links="additionalLinks"
        :template="template"
        :nav-template="navTemplateName"
        :nav-info="navInfo"
        :mobile-expanded="mobileExpanded"
        :current-page-id="currentPageId"
        @close-mobile-menu="closeMobileMenu"
    ></component>
</template>

<script>
export default {
    props: [
        "additionalLinks",
        "mobileExpanded",
        "navInfo",
        "currentPageId",
        "navTemplate",
        "template",
    ],
    data() {
        return {
            navTemplateName: this.navTemplate,
        }
    },
    computed: {
        templateComponent() {
            return 'vue-nav-template-' + (this.navTemplateName ? this.navTemplateName : '1');
        },
    },
    methods: {
        closeMobileMenu(event) {
            this.$emit('close-mobile-menu');
        }
    }
}
</script>

<style lang="scss">

</style>