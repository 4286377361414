<template>
    <div class="vue-site-search-2">
        <div class="vss-input-group form-group">
            <input
                class="form-control"
                type="search"
                placeholder="Search"
                ref="siteSearch"
                v-model="siteSearch"
            />
            <a
                class="search-icon"
                :href="`/search?${encodeURIComponent(siteSearch)}`"
                :title="`Search site for ${siteSearch}`"
            ><span class="fas fa-search"></span></a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            siteSearch: '',
        }
    },
}
</script>

<style lang="scss">
    @import '../../../../../../sass/vue-import';
    $template-theme: var(--template-theme, white);
    $theme-compliant: var(--theme-compliant, black);
    $theme-non-compliant: var(--theme-noncompliant, white);

    body[data-search-template="2"] {
        .vue-site-search-2 {
            display: flex;
            align-items: center;
        }

        .vss-input-group {
            margin: 0;
            position: relative;
            height: 100%;

            input[type="search"] {
                padding-right: 40px;
                height: 100%;
                font-size: 18px;
            }

            .search-icon {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>