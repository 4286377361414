<template>
    <div id="site-config-panel">
        <div class="site-config-nav" v-if="isForbinAdmin">
            <button class="nav-link" title="Edit robots.txt" @click.prevent="openRobotsModal">Robots</button>
            <button class="nav-link" title="Edit sitemap.xml" @click.prevent="openSitemapModal">Sitemap</button>
        </div>
        <div class="site-config-section">
            <div class="mb-1 d-flex flex-row justify-content-between">
                <div class="site-config-section-header d-flex flex-column">
                    <p>From this screen you can modify the site configuration options for your site. Click on the tabs above to edit robots.txt or sitemap.xml.</p>
                </div>
                <div class="site-config-section-button d-flex flex-column justify-content-end mb-1">
                    <button class="btn btn-primary" @click.prevent="updateSiteConfig">{{ isFormProcessing ? 'Processing...' : 'Update Site Config' }}</button>
                </div>
            </div>
            <site-config-list :configs="configs"></site-config-list>
        </div>

        <site-config-robots-modal :is-modal-open="showRobotsModal"/>

        <site-config-sitemap-modal :is-modal-open="showSitemapModal"/>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'SiteConfigLayout',
    computed: {
        ...mapState('siteConfig', {
            configs: state => state.configs,
            isForbinAdmin: state => state.isForbinAdmin,
            showRobotsModal: state => state.robots.isModalOpen,
            showSitemapModal: state => state.sitemap.isModalOpen,
            isFormProcessing: state => state.isFormProcessing
        })
    },
    methods: {
        ...mapActions('siteConfig', {
            fetchSiteConfigData: 'fetchSiteConfigData',
            openRobotsModal: "robots/openModal",
            openSitemapModal: "sitemap/openModal",
            updateSiteConfig: 'updateSiteConfig'
        })
    },
    created() {
        this.fetchSiteConfigData();
    }
}
</script>

<style lang="scss" scoped>
    button.nav-link {
        border-left: 0;
        border-top: 0;
        border-bottom: 0;
    }
</style>