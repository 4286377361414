<template>
    <component
        v-if="template != 'hidden'"
        :is="templateComponent"
        v-bind="{...$props,...$attrs}"
    ></component>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        template: {
            type: String,
            default: "1"
        }
    },
    computed: {
        templateComponent() {
            return `vue-footer-template-${this.template}`;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>