<template>
    <div class="header-template-1" :data-variant="getVariant" :style="getTheme">
        <div class="dynamic-container">
            <div class="top-bar" v-if="primaryLinks || secondaryLinks">
                <div class="primary-links" v-if="primaryLinks">
                    <vue-link
                        v-for="link in primaryLinks"
                        :key="link.text"
                        :link-obj="link"
                    ></vue-link>
                </div>
                <div class="secondary-links" v-if="secondaryLinks">
                    <vue-link
                        v-for="link in secondaryLinks"
                        :key="link.text"
                        :link-obj="link"
                    ></vue-link>
                </div>
            </div>
            <div class="bottom-bar">
                <div class="d-flex align-items-center">
                    <div class="logo">
                        <img :src="logoPath" alt="Company logo">
                        <vue-link link-path="/" link-text="Company name home" link-class="absolute-fill text-throw"></vue-link>
                    </div>
                    <vue-nav
                        :mobile-expanded="mobileNavExpanded"
                        :template="template"
                        :nav-template="navTemplate"
                        :nav-info="navInfo"
                        :current-page-id="currentPageId"
                        @close-mobile-menu="mobileNavExpanded = !mobileNavExpanded"
                    ></vue-nav>
                </div>
                <div class="d-flex align-items-center">
                    <div class="ctas" v-if="primaryCta || secondaryCta">
                        <vue-link v-if="primaryCta" :link-obj="primaryCta"></vue-link>
                        <vue-link v-if="secondaryCta" :link-obj="secondaryCta"></vue-link>
                    </div>
                    <vue-site-search
                        :search-template="searchTemplate"
                    ></vue-site-search>
                    <div class="mobile-controls">
                        <p class="open-mobile-menu" @click="mobileNavExpanded = !mobileNavExpanded">
                            <span class="fas fa-bars"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tinycolor from 'tinycolor2';

export default {
    props: [
        "primaryLinks",
        "secondaryLinks",
        "primaryCta",
        "secondaryCta",
        "variant",
        "logoPath",
        "navInfo",
        "currentPageId",
        "template",
        "navTemplate",
        "searchTemplate",
        "theme"
    ],
    data() {
        return {
            mobileNavExpanded: false
        }
    },
    computed: {
        getTheme() {
            if (this.theme) {
                let themeVars = '';
                let themeColor = getComputedStyle(document.documentElement).getPropertyValue('--' + this.theme);
                let themeCompliant = tinycolor.mostReadable(themeColor, ["#fff", "#000"]).toHexString();
                let themeNonCompliant = tinycolor.mostReadable(themeColor, ["#fff", "#000"]).toHexString() == '#000000' ? '#FFFFFF' : '#000000';
                let themeName = '--template-theme:' + themeColor + ';';
                let themeYiq = '--theme-compliant:' + themeCompliant + ';';
                let themeNotYiq = '--theme-noncompliant:' + themeNonCompliant + ';';
                return themeVars.concat(themeName, themeYiq, themeNotYiq);
            }
        },
        getVariant() {
            return this.variant ? this.variant : false;
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $template-theme: var(--template-theme, white);
    $theme-compliant: var(--theme-compliant, black);

    .logo {
        position: relative;
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: bottom;
        }
    }

    .header-template-1 { background-color: $template-theme; }

    .mobile-controls {
        @include desktop { display: none; }

        .open-mobile-menu {
            font-size: 24px;
            margin: 0 0 0 1rem;
        }
    }

    .top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 -1rem;
        padding: .25rem 0;

        + .bottom-bar {
            position: relative;
            margin-top: 1px;

            &:before {
                position: absolute;
                content: '';
                background-color: $theme-compliant;
                opacity: .2;
                height: 1px;
                width: 100%;
                top: 0;
                left: 0;
            }
        }
    }

    .bottom-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;
    }

    .dynamic-container {

        // Default container variant
        .header-template-1:not([data-variant]) & {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        // Full width variant
        .header-template-1[data-variant="full"] & {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }
    }

    .primary-links,
    .secondary-links {
        display: flex;

        a {
            color: $theme-compliant;
            padding: .25rem 1rem;
        }
    }
</style>