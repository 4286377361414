<template>
    <div class="vue-site-search-1" :data-expanded="searchExpand">
        <p class="show-site-search" @click="searchClick"><span class="fas fa-search"></span></p>
        <div class="vss-input-group form-group">
            <input
                class="form-control"
                type="search"
                placeholder="Search website"
                ref="siteSearch"
                v-model="siteSearch"
                @blur="focusOutSearch"
            />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            siteSearch: '',
            searchExpand: false
        }
    },
    methods: {
        searchClick() {
            this.searchExpand = true;
            console.log(this.$refs.siteSearch);
            this.$refs.siteSearch.focus();
        },
        focusOutSearch(event) {
            this.searchExpand = false;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $template-theme: var(--template-theme, white);
    $theme-compliant: var(--theme-compliant, black);
    $theme-non-compliant: var(--theme-noncompliant, white);

    .vue-site-search-1 {
        display: flex;
        align-items: center;
        transition: 200ms ease-out;

        &[data-expanded] {
            margin-left: 0;
            position: absolute;
            right: 0;
        }

        &:not([data-expanded]) {
            position: relative;
            margin-left: 40px;
        }
    }

    .show-site-search {
        color: $theme-compliant;
        font-size: 24px;
        margin: 0;
        position: absolute;
        line-height: 1;
        z-index: 1;
        cursor: pointer;
        transition: 200ms ease-out;

        .vue-site-search-1[data-expanded] & {
            right: 8px;
            pointer-events: none;
        }

        .vue-site-search-1:not([data-expanded]) & { right: 0; }
    }

    .vss-input-group {
        margin: 0;
        position: absolute;
        right: 0;
        opacity: .4;
        width: 40px;
        overflow-x: hidden;
        transition: 200ms ease-out;

        .vue-site-search-1:not([data-expanded]) & {
            width: 0px;
            opacity: 0;
            pointer-events: none;
        }

        .vue-site-search-1[data-expanded] & {
            @include mobile { width: calc(100vw - 160px); }
            @include not-mobile { width: 400px; }
            opacity: 1;

            input[type="search"] { padding-right: 40px; }
        }

        input[type="search"] { font-size: 18px; }
    }
</style>