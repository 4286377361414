<template>
    <component
        v-if="templateName != 'hidden'"
        :is="templateComponent"
        :current-page-id="currentPageId"
        :logo-path="logoPath"
        :nav-info="navInfo"
        :nav-template="templateNav"
        :primary-cta="primaryCta"
        :primary-links="primaryLinks"
        :search-template="searchTemplate"
        :secondary-cta="secondaryCta"
        :secondary-links="secondaryLinks"
        :template="templateName"
        :theme="templateTheme"
        :variant="templateVariant"
    ></component>
</template>

<script>
export default {
    props: [
        "logoPath",
        "navInfo",
        "navTemplate",
        "pageData",
        "primaryCta",
        "primaryLinks",
        "searchTemplate",
        "secondaryCta",
        "secondaryLinks",
        "template",
        "theme",
        "variant",
    ],
    data() {
        return {
            templateName: this.template,
            templateVariant: this.variant,
            templateTheme: this.theme,
            templateNav: this.navTemplate,
            currentPageId: this.pageData.id
        }
    },
    computed: {
        templateComponent() {
            return 'vue-header-template-' + (this.templateName || '1');
        },
    },
    mounted() {
        document.body.dataset.headerTemplate = this.templateName || '1';
    }

}
</script>

<style lang="scss">
    #vue-header {
        z-index: 100;
    }
</style>