<template>
    <div class="header-template-4" :data-variant="getVariant" :style="getTheme">
        <div class="dynamic-container">
            <div class="logo-container">
                <img :src="logoPath" alt="Company logo">
                <vue-link link-path="/" link-text="Company name home" link-class="absolute-fill text-throw"></vue-link>
            </div>
            <div class="top-bar">
                <vue-site-search
                    :search-template="searchTemplate"
                ></vue-site-search>
                <div class="links" v-if="primaryLinks || primaryCta">
                    <div class="primary-links" v-if="primaryLinks">
                        <vue-link
                            v-for="link in primaryLinks"
                            :key="link.text"
                            :link-obj="link"
                        ></vue-link>
                    </div>
                    <div class="ctas" v-if="primaryCta">
                        <vue-link v-if="primaryCta" :link-obj="primaryCta"></vue-link>
                    </div>
                </div>
                <div class="tooltip-tab" :class="{active: contactTooltipVisible}">
                    <button
                        class="tooltip-btn btn btn-light-tan btn-sm rounded-pill text-uppercase"
                        @click="contactTooltipToggle"
                    ><span class="fas fa-comments d-md-none"></span><span class="d-none d-md-inline">Contact Us</span> <span class="fas fa-chevron-down ml-1"></span></button>
                    <div class="tooltip">
                        <p class="tooltip-heading">Need Help?</p>
                        <p><vue-link link-path="/contact-us" link-text="Contact Us Online"></vue-link></p>
                        <p><vue-link link-path="/locations" link-text="View All Locations &amp; Hours"></vue-link></p>
                        <p class="d-flex justify-content-between">Locke at Tower Park<vue-link class="ml-2" link-path="tel:3190000000" link-text="319-000-0000"></vue-link></p>
                        <p class="d-flex justify-content-between">Locke on 4th<vue-link class="ml-2" link-path="tel:3190000000" link-text="319-000-0000"></vue-link></p>
                        <p class="d-flex justify-content-between">Locke in La Porte City<vue-link class="ml-2" link-path="tel:3190000000" link-text="319-000-0000"></vue-link></p>
                        <p class="d-flex justify-content-between mb-0">Locke Garden View Chapel<vue-link class="ml-2" link-path="tel:3190000000" link-text="319-000-0000"></vue-link></p>
                    </div>
                </div>
                <div class="tooltip-tab" :class="{active: memberTooltipVisible}">
                    <button
                        class="tooltip-btn btn btn-tan btn-sm rounded-pill text-uppercase"
                        @click="memberTooltipToggle"
                    ><span class="fas fa-user-circle d-md-none"></span><span class="d-none d-md-inline">My Account</span> <span class="fas fa-chevron-down ml-1"></span></button>
                    <div class="tooltip">
                        <p class="tooltip-heading">Account Info</p>
                        <template v-if="isLoggedIn">
                            <p>Not <span v-html="'{{ username}}'"></span>? <vue-link link-path="/log-out" link-text="Log Out"></vue-link></p>
                            <p><vue-link link-path="/members/address-book" link-text="Address Book"></vue-link></p>
                            <p><vue-link link-path="/members/order-history" link-text="Order History"></vue-link></p>
                            <p><vue-link link-path="/members/Account Settings" link-text="Account Settings"></vue-link></p>
                            <p><vue-link link-path="/members/Account Planning" link-text="Advance Planning"></vue-link></p>
                        </template>
                        <template v-else>
                            <p><vue-link class="btn btn-blue" link-path="/log-in" link-text="Log In"></vue-link></p>
                            <p>New here? <vue-link link-path="/create-account" link-text="Create Your Account"></vue-link></p>
                        </template>
                    </div>
                </div>
                <vue-link
                    class="cart-button text-decoration-none flex-shrink-0"
                    link-path="/cart"
                    link-icon="fas fa-shopping-cart"
                    link-title-name="your shopping cart"
                    :link-text="cartAmount"
                ></vue-link>
            </div>
            <div class="bottom-bar">
                <div class="d-flex align-items-center">
                    <vue-nav
                        :mobile-expanded="mobileNavExpanded"
                        :template="template"
                        :nav-template="2"
                        :nav-info="navInfo"
                        :current-page-id="currentPageId"
                        :additional-links="combineLinksAndCtas"
                        @close-mobile-menu="mobileNavExpanded = !mobileNavExpanded"
                    ></vue-nav>
                    <div class="mobile-controls">
                        <p class="open-mobile-menu" @click="mobileNavExpanded = !mobileNavExpanded">
                            <span class="fas fa-bars"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tinycolor from 'tinycolor2';

export default {
    props: [
        "primaryLinks",
        "secondaryLinks",
        "primaryCta",
        "secondaryCta",
        "variant",
        "logoPath",
        "navInfo",
        "currentPageId",
        "template",
        "navTemplate",
        "searchTemplate",
        "theme"
    ],
    data() {
        return {
            mobileNavExpanded: false,
            isLoggedIn: true, // This will need to be dynamic at some point
            contactTooltipVisible: false,
            memberTooltipVisible: false,
            cartAmount: "4" // Will need to be set up as well, needs to be string unfortunately
        }
    },
    computed: {
        getTheme() {
            if (this.theme) {
                let themeVars = '';
                let themeColor = getComputedStyle(document.documentElement).getPropertyValue('--' + this.theme);
                let themeCompliant = tinycolor.mostReadable(themeColor, ["#fff", "#000"]).toHexString();
                let themeNonCompliant = tinycolor.mostReadable(themeColor, ["#fff", "#000"]).toHexString() == '#000000' ? '#FFFFFF' : '#000000';
                let themeName = '--template-theme:' + themeColor + ';';
                let themeYiq = '--theme-compliant:' + themeCompliant + ';';
                let themeNotYiq = '--theme-noncompliant:' + themeNonCompliant + ';';
                return themeVars.concat(themeName, themeYiq, themeNotYiq);
            }
        },
        getVariant() {
            return this.variant ? this.variant : false;
        },
        combineLinksAndCtas() {
            let newArray = [];
            newArray.push(this.primaryCta, this.secondaryCta);
            newArray.concat(this.primaryLinks, this.secondaryLinks);
            return newArray;
        }
    },
    methods: {
        addVars() {
            document.body.style.setProperty('--hd-height', this.$el.offsetHeight + 'px');
        },
        contactTooltipToggle() {
            this.contactTooltipVisible = !this.contactTooltipVisible;
            if (this.memberTooltipVisible) this.memberTooltipVisible = false;
        },
        memberTooltipToggle() {
            this.memberTooltipVisible = !this.memberTooltipVisible;
            if (this.contactTooltipVisible) this.contactTooltipVisible = false;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.addVars();
        })
        window.addEventListener('resize', () => {
            this.addVars();
        });
    },
    updated() {
        this.addVars();
    }
}
</script>

<style lang="scss">
    @import '../../../../../../sass/vue-import';

    body[data-header-template="4"] {
        --container-width: 1600px;
        @include fluid-size(4px, 8px, --top-arrow-size, $min-vw: $lg-screen, $max-vw: 1400px);

        section.page-title {
            grid-area: header-start / header-start / pagetitle-end / pagetitle-end;
            padding: calc(var(--hd-height) + 2rem) 0 2rem;
            margin: 0;
            max-width: 100%;
        }

        #vue-header {
            position: sticky;
            top: 0;
        }
    }
</style>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $template-theme: var(--template-theme, white);
    $theme-compliant: var(--theme-compliant, black);
    $border-radius: 20px; // setting statically for now while we figure out why variables aren't making it into the vue import

    .header-template-4 {
        @include fluid-size(80px, 175px, --logo-width);
        position: relative;

        // Default container variant
        &:not([data-variant]) .dynamic-container {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        // Full width variant
        &[data-variant="full"] .dynamic-container {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        .dynamic-container {
            display: grid;
            grid-template:
                "l t" max-content
                "l b" max-content
                "l ." max-content
                / var(--logo-width) 1fr;
        }

        .logo-container { grid-area: l; }
        .top-bar { grid-area: t; }
        .bottom-bar { grid-area: b; }

        .logo-container {
            position: relative;
            background-color: white;
            border-radius: 0 0 $border-radius $border-radius;
            @include fluid-size(8px, 16px, padding);
            height: var(--logo-width);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            img {
                max-width: 100%;
                max-height: 100%;
                vertical-align: bottom;
            }
        }

        .top-bar {
            padding: .25rem 0;
            font-size: 14px;
            position: relative;
            grid-area: t;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-transform: uppercase;
            text-decoration: none;
            color: var(--blue) !important;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -100vw;
                height: 100%;
                width: 200vw;
                background-color: var(--tan);
                z-index: -1;
            }

            &::v-deep {
                .vue-site-search-1 {
                    font-size: 14px;
                    flex-grow: 1;
                    margin: 0 1rem;
                    position: relative !important;

                    .show-site-search {
                        font-size: 14px;
                        color: var(--blue);
                        }

                    .vss-input-group {
                        position: relative;
                        width: 100%;
                    }
                }
            }

            .links {
                flex-shrink: 0;
                margin: 0 -1rem;
                display: flex;
                align-items: center;

                .ctas { padding: 0 1rem; }
            }

            .tooltip-tab {
                @include fluid-size(8px, 16px, margin-left);
                height: 100%;
                display: flex;
                align-items: center;
                position: relative;
                flex-shrink: 0;
                --top-arrow-size: min(8px, 1vw);

                .tooltip-btn {
                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -1px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: calc(var(--top-arrow-size) * 2);
                        width: calc(var(--top-arrow-size) * 10);
                        border-width: 0 calc(var(--top-arrow-size) * 5) calc(var(--top-arrow-size) * 2);
                        border-style: solid;
                        border-color: var(--light-tan) transparent;
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                        transition: 200ms ease-out;
                    }
                }

                .tooltip {
                    text-transform: none;
                    position: absolute;
                    width: max-content;
                    right: 0;
                    top: 100%;
                    padding: .75rem 1rem;
                    background-color: var(--light-tan);
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: 200ms ease-out;
                    z-index: 3;

                    p {
                        font-size: 14px;
                        color: black;
                        margin: .25rem 0;
                    }

                    .tooltip-heading {
                        font-size: 20px;
                        color: var(--dark-tan);
                        margin-top: 0;
                    }
                }

                &.active {
                    .tooltip-btn:before {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: all;
                        bottom: calc(-1rem - 1px);
                    }

                    .tooltip {
                        top: calc(100% + 1rem + 1px);
                        opacity: 1;
                        visibility: visible;
                        pointer-events: all;
                    }
                }
            }

        }

        .bottom-bar {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: -100vw;
                height: 100%;
                width: 200vw;
                background-color: var(--blue); // update once theming changes are merged in
                opacity: .8;
                z-index: -1;
            }
        }

        .mobile-controls {
            @include desktop { display: none; }

            .open-mobile-menu {
                color: white; // update once theming changes are merged in
                font-size: 24px;
                margin: 0 0 0 1rem;
            }
        }

        .primary-links {
            display: flex;
            padding: 0 .5rem;

            a { padding: .25rem .5rem; }
        }

        .cart-button {
            @include fluid-size(8px, 16px, margin-left);
        }
    }
</style>