<template>
    <div class="vn-family"
        :data-navid="pageId"
        :data-current="isCurrentPage"
        :data-depth="currentDepth"
    >
        <vue-link
            link-class="vn-item"
            :link-attributes="{'data-depth': currentDepth}"
            :link-path="pageLink"
            :link-text="pageName"
        ></vue-link>
        <div class="vn-children" v-if="navItem.children.length" :data-depth="currentDepth">
            <template v-for="ni in navItem.children">
                <vue-nav-item
                    :depth="childDepth"
                    v-if="ni.page.show_in_nav"
                    :key="ni.page.id"
                    :nav-item="ni"
                    :current-page-id="currentPageId"
                ></vue-nav-item>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        "navItem",
        "currentPageId",
        "depth"
    ],
    data() {
        return {
            pageId: this.navItem.page.id,
            pageLink: this.navItem.page.url,
            pageName: this.navItem.page.nav_title,
            currentDepth: this.depth,
            childDepth: this.depth + 1
        }
    },
    computed: {
        isCurrentPage() {
            return this.pageId === this.currentPageId;
        }
    }
}
</script>

<style lang="scss">

</style>