<template>
    <div class="header-template-3" :data-variant="getVariant" :style="getTheme">
        <div class="top-bar">
            <div class="dynamic-container">
                <div class="top">
                    <vue-site-search
                        :search-template="searchTemplate"
                    ></vue-site-search>
                    <div class="links" v-if="primaryLinks || primaryCta">
                        <div class="primary-links" v-if="primaryLinks">
                            <vue-link
                                v-for="link in primaryLinks"
                                :key="link.text"
                                :link-obj="link"
                            ></vue-link>
                        </div>
                        <div class="ctas" v-if="primaryCta">
                            <vue-link v-if="primaryCta" :link-obj="primaryCta"></vue-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-bar">
            <div class="dynamic-container">
                <div class="bottom">
                    <div class="logo">
                        <img :src="logoPath" alt="Company logo">
                        <vue-link link-path="/" link-text="Company name home" link-class="absolute-fill text-throw"></vue-link>
                    </div>
                    <div class="d-flex align-items-center">
                        <vue-nav
                            :mobile-expanded="mobileNavExpanded"
                            :template="template"
                            :nav-template="navTemplate"
                            :nav-info="navInfo"
                            :current-page-id="currentPageId"
                            :additional-links="combineLinksAndCtas"
                            @close-mobile-menu="mobileNavExpanded = !mobileNavExpanded"
                        ></vue-nav>
                        <div class="mobile-controls">
                            <p class="open-mobile-menu" @click="mobileNavExpanded = !mobileNavExpanded">
                                <span class="fas fa-bars"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tinycolor from 'tinycolor2';

export default {
    props: [
        "primaryLinks",
        "secondaryLinks",
        "primaryCta",
        "secondaryCta",
        "variant",
        "logoPath",
        "navInfo",
        "currentPageId",
        "template",
        "navTemplate",
        "searchTemplate",
        "theme"
    ],
    data() {
        return {
            mobileNavExpanded: false
        }
    },
    computed: {
        getTheme() {
            if (this.theme) {
                let themeVars = '';
                let themeColor = getComputedStyle(document.documentElement).getPropertyValue('--' + this.theme);
                let themeCompliant = tinycolor.mostReadable(themeColor, ["#fff", "#000"]).toHexString();
                let themeNonCompliant = tinycolor.mostReadable(themeColor, ["#fff", "#000"]).toHexString() == '#000000' ? '#FFFFFF' : '#000000';
                let themeName = '--template-theme:' + themeColor + ';';
                let themeYiq = '--theme-compliant:' + themeCompliant + ';';
                let themeNotYiq = '--theme-noncompliant:' + themeNonCompliant + ';';
                return themeVars.concat(themeName, themeYiq, themeNotYiq);
            }
        },
        getVariant() {
            return this.variant ? this.variant : false;
        },
        combineLinksAndCtas() {
            let newArray = [];
            newArray.push(this.primaryCta, this.secondaryCta);
            newArray.concat(this.primaryLinks, this.secondaryLinks);
            return newArray;
        }
    },
    methods: {
        addVars() {
            document.body.style.setProperty('--hd-height', this.$el.offsetHeight + 'px');
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.addVars();
        })
        window.addEventListener('resize', () => {
            this.addVars();
        });
    },
    updated() {
        this.addVars();
    }
}
</script>

<style lang="scss">
    body[data-header-template="3"] {
        section.page-title {
            grid-area: header-start / header-start / pagetitle-end / pagetitle-end;
            padding: calc(var(--hd-height) + 2rem) 0 2rem;
            margin: 0;
            max-width: 100%;
        }

        #vue-header {
            position: sticky;
            top: 0;
        }
    }
</style>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $template-theme: var(--template-theme, white);
    $theme-compliant: var(--theme-compliant, black);
    $border-radius: 20px; // setting statically for now while we figure out why variables aren't making it into the vue import

    .header-template-3 {

        // Default container variant
        &:not([data-variant]) .dynamic-container {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        // Full width variant
        &[data-variant="full"] .dynamic-container {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        .top-bar {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: $template-theme;
                opacity: .8;
            }
        }

        .top {
            position: relative;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            margin: 0 -1rem;

            .vue-site-search-2 {
                flex-grow: 1;
                padding: 0 1rem;

                .vss-input-group { width: 100%; }

                .search-icon { color: $theme-compliant; }

                input[type="search"] {
                    border-radius: 0;
                    width: 100%;
                    font-size: 14px;
                    background-color: $template-theme;
                    color: $theme-compliant;

                    &::placeholder {
                        text-transform: uppercase;
                        color: rgba($theme-compliant, .5);
                    }
                }
            }

            .links {
                padding: .25rem 1rem;
                display: flex;
                align-items: center;

                .ctas { padding: 0 1rem; }
            }
        }

        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .logo {
            position: relative;
            background-color: white;
            border-radius: 0 0 $border-radius $border-radius;
            @include fluid-size(8px, 16px, padding);
            @include fluid-size(80px, 125px, height);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            img {
                max-width: 100%;
                max-height: 100%;
                vertical-align: bottom;
            }
        }

        .mobile-controls {
            @include desktop { display: none; }

            .open-mobile-menu {
                color: $theme-compliant;
                font-size: 24px;
                margin: 0 0 0 1rem;
            }
        }

        .primary-links,
        .secondary-links {
            display: flex;

            a {
                color: $theme-compliant;
                padding: .25rem 1rem;
            }
        }
    }
</style>