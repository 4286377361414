import { logFormDataValues } from "./form-utils";
import { logApplicationError, getPropertyIfExists } from "./functions";
import {openApplicationErrorModal,openFileManager,showNotification} from "../vpanel/vpanel-functions";

require("./search");

window.axios = require('axios');

window.Vue = require('vue');

/**
 * The following block of code automatically registers
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
const modulesComponents = require.context('../../../Vue', true, /\.vue$/i);
modulesComponents.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], modulesComponents(key).default));


window.logFormDataValues = logFormDataValues;
window.logApplicationError = logApplicationError;
window.getPropertyIfExists = getPropertyIfExists;
window.openApplicationErrorModal = openApplicationErrorModal;
window.openFileManager=openFileManager;
window.showNotification=showNotification;