<template>
    <div class="footer-template-1" :data-variant="variant" :style="getTheme">
        <div class="top-bar">
            <div class="dynamic-container">
                <div class="top-links">
                    <vue-link
                        v-for="(navItem,index) in getShownNav"
                        :key="index"
                        :link-text="navItem.page.nav_title"
                        :link-path="navItem.page.url"
                    ></vue-link>
                </div>
            </div>
        </div>
        <div class="bottom-bar">
            <div class="dynamic-container">
                <div class="copyright-bar">
                    <div class="left">
                        <p class="m-0">&#169; {{ new Date().getFullYear() }} {{ companyName }}. All rights reserved.</p>
                        <p class="legal-links" v-if="legalLinks.length">
                            <template v-for="(link,index) in legalLinks">
                                <span v-if="index > 0" :key="`legal-${index}-sep`">|</span>
                                <vue-link :key="`legal-${index}`" :link-obj="link"></vue-link>
                            </template>
                        </p>
                    </div>
                    <div class="right">
                        <p class="m-0">Web solutions provided by <vue-link link-text="VGM Forbin" link-path="https://www.forbin.com/" link-title=""></vue-link>.</p>
                        <p class="m-0">VGM&nbsp;Forbin is a division of The VGM&nbsp;Group.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tinycolor from 'tinycolor2';

export default {
    inheritAttrs: false,
    props: {
        variant: {
            type: [String, Boolean],
            default: false
        },
        theme: {
            type: String,
            default: 'white'
        },
        pageData: {
            type: Object,
            required: true
        },
        navInfo: {
            type: Array,
            required: true
        },
        companyName: {
            type: String,
            default: 'Forbin'
        },
        legalLinks: {
            type: Array,
        }
    },
    computed: {
        getTheme() {
            if (this.theme) {
                let themeVars = '';
                let themeColor = getComputedStyle(document.documentElement).getPropertyValue('--' + this.theme);
                let themeCompliant = tinycolor.mostReadable(themeColor, ["#fff", "#000"]).toHexString();
                let themeNonCompliant = tinycolor.mostReadable(themeColor, ["#fff", "#000"]).toHexString() == '#000000' ? '#FFFFFF' : '#000000';
                let themeName = '--template-theme:' + themeColor + ';';
                let themeYiq = '--theme-compliant:' + themeCompliant + ';';
                let themeNotYiq = '--theme-noncompliant:' + themeNonCompliant + ';';
                return themeVars.concat(themeName, themeYiq, themeNotYiq);
            }
        },
        getShownNav() {
            // Gets first 5, top-level nav items that are marked as shown
            return this.navInfo.filter((navItem, index) => {
                return navItem.page.show_in_nav && index < 5;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../sass/vue-import';
    $template-theme: var(--template-theme, white);
    $theme-compliant: var(--theme-compliant, black);

    .top-bar {
        background-color: $template-theme;
        padding: 1rem 0;
        border-top: 1px solid rgba(black, .2);

        .top-links {
            display: flex;
            justify-content: center; // fallback
            justify-content: space-evenly;
            flex-wrap: wrap;
            align-items: center;
            margin: -.5rem;
        }

        .vue-link {
            @include fluid-size(14px, 18px, font-size);
            color: $theme-compliant;
            padding: .5rem;
            font-weight: bold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .bottom-bar {
        background-color: $template-theme;
        padding: 1rem 0;
        border-top: 1px solid rgba(black, .2);
        @include fluid-size(14px, 16px, font-size);
    }

    .copyright-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            justify-content: center;
        }

        .left,
        .right {
            text-align: center;
        }

        @include not-mobile {
            .left { text-align: left; }
            .right { text-align: right; }
        }

    }

    .legal-links {
        margin: 0;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin: -.5rem;

        .vue-link {
            margin: .5rem;
        }
    }

    .dynamic-container {
        // Default container variant
        .footer-template-1:not([data-variant]) & {
            max-width: var(--container-width, 1140px);
            margin-left: auto;
            margin-right: auto;
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }

        // Full width variant
        .footer-template-1[data-variant="full"] & {
            padding-left: calc((var(--grid-gutter-width, 30px) / 2));
            padding-right: calc((var(--grid-gutter-width, 30px) / 2));
        }
    }

</style>